.scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.4s ease-in-out, bottom 0.3s ease-in-out, transform 0.2s ease-in-out;
  background: transparent;
  box-shadow: none;
}

.scroll-to-top.show {
  opacity: 0.8; /* Default visible opacity */
}

.scroll-to-top:hover {
  opacity: 1;
  transform: scale(1.1);
}

.scroll-icon {
  width: 50px;
  height: 50px;
  opacity: 0.9;
}
