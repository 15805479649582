/* Home Container */
.home-container {
  color: #333;
  padding: 0;
  margin: 0;
}

/* Banner Section */
.banner {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.banner video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the container */
  opacity: 1;
  z-index: -1;
}

/* Welcome Section */
.welcome {
  padding: 60px 20%;
  text-align: center;
  line-height: 1.5rem;
}

.welcome h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.welcome p {
  font-size: 1.2rem;
  line-height: 1.8;
}

/* About Section */
.about {
  padding: 60px 8%;
}

.about h2 {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 60px;
}

.about .feature-list {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.about .feature-item {
  display: flex;
  align-items: center; /* Align items in the center vertically */
  gap: 5%;
}

.about .feature-item:nth-child(odd) {
  flex-direction: row; /* Image on the left, text on the right */
}

.about .feature-item:nth-child(even) {
  flex-direction: row-reverse; /* Image on the right, text on the left */
}

.about .feature-item img {
  width: 45%; /* Default for large screens */
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.about .text-container {
  flex: 1;
}

.about h3 {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.about p {
  font-size: 0.9rem;
  line-height: 1.6;
  text-align: justify;
}

/* Values Section */
.values {
  padding: 60px 10%;
}

.values h2 {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.values .feature-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
}

.values .feature-item {
  max-width: 300px;
  margin: 20px;
  text-align: center;
}

.values h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.values p {
  font-size: 0.9rem;
  line-height: 1.5;
}

/* Values Section - Circular Images */
.values .feature-item img {
  width: 200px; /* You can adjust the size */
  height: 200px; /* Ensure the height is the same as the width to make it a circle */
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image covers the area without distortion */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for better visibility */
  margin-bottom: 15px; /* Space below the image */
}


/* Responsive Adjustments */
@media (max-width: 1024px) {
  .about .feature-item {
    gap: 30px;
  }

  .about .feature-item img{
    width: 45%;
    margin: 0 auto;
  }

  .about h3 {
    font-size: 1.3rem;
  }

  .about p {
    font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  .about .feature-item {
    flex-direction: column;
    text-align: center;
    flex-wrap: wrap;  /* Allow wrapping on smaller screens */
  }

  .about .feature-item img{
    width: 90%;
    margin: 0 auto;
  }

  .about h3 {
    font-size: 1.2rem;
  }

  .about p {
    font-size: 0.95rem;
    line-height: 1.5;
  }

  .values .feature-list {
    gap: 30px;
  }

  .values .feature-item {
    max-width: 100%;
    margin: 0 10px;
  }
}

@media (max-width: 480px) {
  .about {
    padding: 40px 5%;
  }

  .about h2 {
    font-size: 1.5rem;
  }

  .about .teamImg {
    width: 95%;
  }

  .about h3 {
    font-size: 1rem;
  }

  .about .feature-item{
    flex-direction: reverse;
  }

  .about p {
    font-size: 0.85rem;
    line-height: 1.4;
  }

  .values .feature-list {
    gap: 20px;
  }

  .values .feature-item {
    margin: 10px 5px;
  }
}
