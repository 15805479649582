.contact-form-container {
    display: block;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2spx 4px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-weight: lighter;
  }
  
  .contact-form-container form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form-container input,
  .contact-form-container textarea {
    padding: 10px;
    background: rgba(126, 123, 126, 0.052);
    margin-bottom: 15px;
    border: none;
    font-size: 14px;
    height: max-content;
  }

  .contact-form-container .message{
    text-align: center;
    margin-bottom: 20px;
    font-size: 0.9rem;
  }

  .contact-form-container textarea {
    height: 120px; /* Set height for the textarea */
    min-height: 120px; /* Set height for the textarea */
    resize: vertical; /* Allow vertical resizing */
    max-height: 300px;
  }


  .contact-form-container button {
    position: relative;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    background: white;
    border: 2px solid #78691e9c;
    color: #483c019c;
    padding: 15px 30px;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.1s ease;
  }

   /* Create the left line */
   .line-button::before,
   .line-button::after {
       content: "";
       display: block;
       width: 30px; /* Adjust the width of the lines */
       height: 2px;
       background-color: #78691e9c;
       margin: 0 10px;
   }
  
  .contact-form-container button line {
    border: none;
    border-top: 2px solid #000; /* Adjust thickness and color */
    margin: 20px 0; /* Adjust spacing */
  }

  .contact-form-container button:hover {
    background-color: #78691ed4;
  }

  .contact-form {
    scroll-margin-top: 100px; /* Adjust this value for the desired offset */
  }
  
/* Status Pop Up*/
/* Prevent scrolling and clicking on background */
.status-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  pointer-events: all; /* Enable pointer events on the modal */
}

.status-message {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  position: relative;
  z-index: 1001;
}

.close-button {
  margin-top: 10px;
  padding: 8px 16px;
  background: #78691ed4;
  color: white;
  cursor: pointer;
}

.close-button:hover {
  background: #78691ed4;
}

/* Disable scrolling on the background */
body.modal-open {
  overflow: hidden;
}

/* ReCAPTCHA */
/* Align reCAPTCHA to the right */
.recaptcha-container {
  display: flex;
  justify-content: center;  /* Align to the right */
  margin-top: 10px;
  margin-bottom: 15px;
  filter: grayscale(70%) contrast(100%);
}