Modal overlay to cover the screen
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal content box */
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    position: relative;
    overflow-y: auto;
    max-height: 80%;
  }
  
  /* Close button */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  /* Animation for the modal */
@keyframes slide-down {
  from {
      transform: translateY(-20%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}
  