/* Position the banner text on the video */
.banner-text {
  position: absolute; /* Positioned relative to the .banner */
  bottom: 30%; /* Place the text near the bottom of the screen */
  display: flex;
  flex-direction: column;
  align-items: center; /* Align text to the center */
  text-align: center;
  z-index: 1; /* Ensure it's above the video */
  pointer-events: none; /* Prevent interaction with text, optional */
  width: 50%; /* Make the text block responsive */
}

/* Styling for each line */
.banner-text-line {
  position: absolute; /* All lines stack in the same place */
  font-size: clamp(4rem, 4vw, 8rem); /* Responsive font size */
  font-weight: bold;
  color: transparent; /* Gradient-only text */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-size: 200%; /* Smooth gradient transitions */
  opacity: 0; /* Hidden initially */
  transform: scale(0.9); /* Start smaller */
  background-image: linear-gradient(90deg, 
      #ffe8f0, /* Very light pink (light start) */
      #d8d9f5, /* Soft lavender (light) */
      #a2c6f2, /* Light sky blue (transitional) */
      #5ca1d6, /* Medium blue (darker) */
      #327d91, /* Deep teal (dark transition) */
      #18453b  /* Rich forest green (dark end) */
      );
  animation: dissolveEffect 28s ease-in-out infinite,
             waveEffect 3s linear infinite;; /* Increased duration for smoother transitions */
}

/* Wave animation for moving gradients inside text */
@keyframes waveEffect {
  0% {
    background-position: 0% 50%; /* Gradient starts at the left */
  }
  50% {
    background-position: 100% 50%; /* Gradient moves to the right */
  }
  100% {
    background-position: 0% 50%; /* Gradient resets to the left */
  }
}

/* Unique timing for each text line with increased delays */
.banner-text-line:nth-child(1) {
  animation-delay: 0s; /* Starts immediately */
}

.banner-text-line:nth-child(2) {
  animation-delay: 7s; /* Starts after the first line with a longer gap */
}

.banner-text-line:nth-child(3) {
  animation-delay: 14s; /* Starts after the second line with a longer gap */
}

.banner-text-line:nth-child(4) {
  animation-delay: 21s; /* Starts after the third line with a longer gap */
}

/* Animation for dissolve in and out without blur */
@keyframes dissolveEffect {
  0% {
    opacity: 0;
    transform: scale(0.9); /* Slightly smaller initially */
  }
  10%{
    opacity: 1;
    transform: scale(1); /* Normal size */
  }
  25%{
    opacity: 0; /* Fade out completely */
    transform: scale(1.1); /* Slightly enlarged during fade-out */
  }
  40%, 50% {
    opacity: 0; /* Fade out completely */
    transform: scale(1); /* Slightly enlarged during fade-out */
  }
  100% {
    opacity: 0; /* Reset opacity */
    transform: scale(0.9); /* Reset size */
  }
}

/* Responsive design for smaller screens */
@media screen and (max-width: 768px) {
  .banner-text {
    bottom: 30%;
    width: 100%;
  }
  .banner-text-line {
    font-size: clamp(2.25rem, 4vw, 4.5rem); /* Increased font size for smaller screens */
  }
}

/* Responsive design for iPads */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .banner-text {
    bottom: 20%;
    width: 100%;
  }
  .banner-text-line {
    font-size: clamp(3.25rem, 4vw, 4.5rem); /* Larger font size for iPads */
  }
}
