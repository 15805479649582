/* Modal overlay to cover the screen */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: none; /* Prevent interaction with the overlay */
}

/* Modal content box */
.modal-content {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 5%;
  width: 95%; /* Increased width */
  position: relative;
  overflow-y: auto;
  max-height: 90%; /* Adjusted height for larger content */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  animation: slide-down 0.3s ease-out; /* Smooth slide-down effect */
  pointer-events: auto; /* Re-enable interaction for the modal */
}

/* Animation for the modal */
@keyframes slide-down {
  from {
      transform: translateY(-20%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

/* Close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: #933030; /* Highlight on hover */
}

/* Styling for the modal content */
.modal-content h1 {
  color: #333;
  font-size: 22px;
  margin-bottom: 10px;
}

.modal-content h2 {
  color: #555;
  font-size: 15px;
  margin-top: 15px;
}

.modal-content h3 {
  color: #555;
  font-size: 14px;
  margin-top: 14px;
}


.modal-content p,
.modal-content li {
  margin-top: 5px; /* Reduced space above p */
  color: #666;
  font-size: 13px;
  line-height: 1.6;
}

.modal-content ul {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  list-style: disc;
}

.modal-content a {
  color: #8f9ba7;
  text-decoration: none;
}

.modal-content a:hover {
  text-decoration: underline;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .modal-content {
    padding: 5% 8%; /* Adjust padding to provide a better layout */
    text-align: left; /* Ensure text alignment to the left */
  }

  .modal-content ul {
    margin-left: 0; /* Remove extra indentation for the list */
    padding-left: 20px; /* Add padding for list alignment */
  }

  .modal-content h1,
  .modal-content h2,
  .modal-content p,
  .modal-content li {
    text-align: left; /* Ensure all text aligns to the left */
  }
}
