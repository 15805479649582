/* General Footer Styles */
.footer {
  background: linear-gradient(45deg,#222 ,#14354e); 
  color: #fff; 
  padding: 60px 0;
  font-family: Arial, Helvetica, sans-serif;
    position: relative;
}

.footer-container {
  display: flex;
  justify-content: space-between; /* Align items to the left, center, and right */
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 0 auto; 
  padding: 0 10px;
  gap: 30px; /* Spacing between elements */
}

.footer-logo {
  flex: 1;
  margin-top: 50px;
}

.footer-logo .logo {
  max-width: 200px;
}

.logo-text {
  font-size: 12px;
  margin-top: -1px;
  text-align: center;
  margin-top: -100 px;
  color: #fff;
  opacity: 0; /* Initially hidden */
  transform: translateX(80px); /* Initial position slightly below */
  transition: opacity 2s ease, transform 2s ease; /* Smooth transition */
  font-style: italic;
}

/* When the footer is in view, apply animation */
.footer.animate-text .logo-text {
  opacity: 1;
  transform: translateY(0); /* Animate to the normal position */
}

.footer-section {
  flex: 1;
  margin: 0 20px;
}

.footer-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #f9f9f9;
}

/* Specific section styles for site info */
.site-info p {
  margin-bottom: 15px; /* Adds a gap between the text items */
}

/* Add cursor styling for clickable items */
.site-info p:hover {
  cursor: pointer; /* Changes the cursor to a hand icon */
}

/* Footer Site Info Container */
.footer-section.site-info div {
  display: flex;
  flex-direction: column;
  gap: 5px; /* Adjust the gap here */
}

.footer-section.site-info a {
  color:#bbb;
  text-decoration: none;
}

.footer-section p {
  font-size: 14px;
  color: #bbb;
  margin: 5px 0;
}

/* Specific section styles */
.follow-us {
  text-align: center; /* Center align the social icons */
}

.social-icons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
}

.social-icons a {
  font-size: 20px;
  color: #fff;
  transition: color 0.3s ease-in-out;
}

.social-icons a:hover {
  color: #1C567E; /* Twitter blue color for hover */
}

.social-icons i {
  display: inline-block;
}

.footer-credit {
  text-align: center;
  font-size: 14px;
  color: #bbb;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Adjust spacing for smaller screens */
  }

  .footer-logo {
    margin-bottom: 30px;
  }

  .footer-section {
    text-align: center;
    margin: 10px 0;
  }

  .social-icons {
    justify-content: center;
  }

  .contact-us {
    text-align: center; /* Center the contact info on mobile */
  }
}
