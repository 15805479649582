/* ==================== Investor Relations Section ==================== */
.investor-relations {
  width: 100%;
  padding: 5% 0;
  text-align: center;
}

.investor-relations h1 {
  font-size: 3.5rem;
  margin: 0;
  font-weight: bold;
  background: linear-gradient(90deg, #ff6ec4, #7873f5, #097cd4, #6ab8e9, #2eeb6a, #fcb045);
  background-size: 300%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color: transparent;
  animation: wave 4s ease-in-out infinite;
}

@keyframes wave {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.investor-relations p {
  font-size: 1rem;
  line-height: 1.8;
  color: #333;
  margin: 0 5%;
  padding-top: 3%;
  text-align: center;
}

/* ==================== Investor Relation Form Section ==================== */

.investor-relation-form {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 40px;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px groove rgba(182, 177, 177, 0.596);
  border-radius: 50px;
  margin-top: 50px;
}

.left-section {
  flex: 0 0 50%; /* Decrease the width of the left section */
  max-width: 50%; /* Ensure it doesn't grow beyond 40% */
}

.left-section h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

.left-section p {
  font-size: 1rem;
  color: #333;
  line-height: 1.8;
  text-align: left;
}

.submissionForm {
  flex: 1 0 40%; /* Increase the right section's width to 60% */
  max-width: 40%; /* Ensure it doesn't grow beyond 60% */
  background: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.submissionForm h2 {
  font-size: 1.2rem;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 50px;
}

.submissionForm input,
.submissionForm textarea {
  padding: 12px;
  background: rgba(126, 123, 126, 0.052);
  margin-bottom: 15px;
  border: 1px solid #ddd;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

.submissionForm textarea {
  resize: vertical;
  height: 120px;
  min-height: 120px;
  max-height: 300px;
}

.submissionForm button {
  padding: 15px 30px;
  background: white;
  border: 2px solid #78691e9c;
  color: #483c019c;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submissionForm button:hover {
  background-color: #78691ed4;
}

.submissionForm .message {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1rem;
}

.submissionForm .line-button::before,
.submissionForm .line-button::after {
  content: "";
  display: block;
}

.status-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.status-message {
  background-color: white;
  padding: 30px;
  text-align: center;
  border-radius: 8px;
}

.close-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #ff6ec4;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

/* ==================== Responsive Styles ==================== */

@media (max-width: 768px) {
  .investor-relations{
    margin-top: 50px;
    scroll-margin-top: 50px;
  }

  .investor-relation-form {
    flex-direction: column;
    padding: 20px;
  }

  .left-section {
    max-width: 100%; /* Make the left section full width on smaller screens */
    margin-bottom: 20px; /* Space between sections */
  }

  .submissionForm {
    max-width: 100%; /* Ensure the form takes full width */
  }

  .status-message {
    width: 90%;
  }

}

/* For Medium Devices (Tablet, including iPad) */
@media (min-width: 768px) and (max-width: 1024px) {
  .investor-relations{
    margin-top: 50px;
    scroll-margin-top: 50px;
  }

  .investor-relation-form {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }

  .left-section {
    max-width: 100%; /* Full width on tablet */
    margin-bottom: 20px; /* Space between sections */
  }

  .submissionForm {
    flex: 1;
    max-width: 100%; /* Ensure the form takes full width */
    margin-right: 20px; /* Margin on the right side */
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .status-message {
    width: 90%;
  }

  .investor-relation-form input,
  .investor-relation-form textarea {
    font-size: 16px;
  }
  
}


