.animation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack logo and text vertically */
  background: rgba(255, 255, 255, 0.95); /* Slightly transparent white */
  z-index: 9999; /* Ensure it overlays everything */
}

.animation-logo {
  width: 500px; /* Logo size */
  height: auto; /* Maintain aspect ratio */
  animation: fadeInOut 5s infinite; /* Smooth repeated fade-in and fade-out */
}

.animation-text {
  margin-top: 10px; /* Spacing between logo and text */
  font-size: 1rem;
  font-weight: lighter;
  font-style: italic;
  color: #a9aaaa; /* Sync text color with logo (adjust as needed) */
  opacity: 0; /* Hidden initially */
  animation: slideInFromRight 5s infinite, fadeInOutText 3s forwards; /* Sync with logo but end at 3.5 seconds */
  animation-delay: 2s; /* Start after 2 seconds */
  transform: translateX(20px); /* Start slightly from the right */
}

/* Fade-in and fade-out animation for the logo */
@keyframes fadeInOut {
  0%, 100% {
    opacity: 0; /* Fully transparent */
  }
  50% {
    opacity: 1; /* Fully visible */
  }
}

/* Slide-in animation for the text (slightly right to center) */
@keyframes slideInFromRight {
  0% {
    transform: translateX(150%); /* Start off-screen (right) */
    opacity: 0; /* Hidden */
  }
  50% {
    transform: translateX(140px); /* Move slightly towards center */
    opacity: 1; /* Fully visible */
  }
  100% {
    transform: translateX(100px); /* Fully centered */
    opacity: 1; /* Fully visible */
  }
}

/* Fade-in and fade-out animation for the text (ends at 3.5s) */
@keyframes fadeInOutText {
  0%, 100% {
    opacity: 0; /* Fully transparent */
  }
  50% {
    opacity: 1; /* Fully visible */
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .animation-logo {
    width: 350px; /* Adjust logo size on smaller screens */
  }

  .animation-text {
    font-size: 0.9rem; /* Make text slightly smaller on mobile */
    transform: translateX(30px); /* Move text more right */
  }

  /* Move entire animation up slightly */
  .animation-overlay {
    transform: translateY(-10%); /* Move entire animation container up */
  }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(200%); /* Start even further off-screen for mobile */
      opacity: 0;
    }
    50% {
      transform: translateX(100px); /* Adjust how far the text comes in */
      opacity: 1;
    }
    100% {
      transform: translateX(50px); /* Center it more properly on mobile */
      opacity: 1;
    }
  }
}

@media (max-width: 480px) {
  .animation-logo {
    width: 250px; /* Further adjust logo size for very small screens */
  }

  .animation-text {
    font-size: 0.8rem; /* Make text smaller on very small screens */
    transform: translateX(40px); /* Move text further off-screen */
  }

  /* Move entire animation up even more */
  .animation-overlay {
    transform: translateY(-15%); /* Move entire animation container up more */
  }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(250%); /* Start far right on very small screens */
      opacity: 0;
    }
    50% {
      transform: translateX(80px); /* Adjust sliding position */
      opacity: 1;
    }
    100% {
      transform: translateX(30px); /* Final centered position */
      opacity: 1;
    }
  }
}
