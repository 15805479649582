/* src/App.css */
* {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
}

.App {
  scroll-behavior: smooth;
}

.content {
  padding-top: 80px; /* Offset for fixed navbar */
}

section {
  padding: 100px 20px;
  border-bottom: 1px solid #f0eeee;
}
