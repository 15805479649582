/* Navbar Styles */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 60px;
  background-color: #fff;
  color: #000;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-sizing: border-box; /* Ensures padding does not affect the layout width */
  transition: opacity 0.3s ease, backdrop-filter 0.3s ease; /* Smooth transition for opacity and blur */
  backdrop-filter: blur(0px); /* Initial backdrop blur */
  WebkitBackdropFilter: blur(0px); /* For Safari support */
}

.logo {
  width: auto;
  height: 40px; /* Adjust as needed to fit navbar height */
  max-width: 160px; /* Optional max width for scaling */
  transition: margin-left 0.3s, margin-right 0.3s, filter 0.3s; /* Add filter transition for blur */
  margin-left: auto; /* Moves the logo to the right */
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;
  justify-content: center;
  padding-right: 20%;
  transition: filter 0.3s; /* Add transition for blur effect */
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  text-decoration: none;
  color: #090000;
  font-size: 13px;
  transition: color 0.3s, filter 0.3s; /* Add filter transition for text blur */
}

.nav-links a:hover {
  color: #ddd;
}

/* Hamburger Menu Styles */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
  position: absolute;
  left: 20px; /* Move hamburger to the left */
}

.hamburger div {
  width: 25px;
  height: 2px; /* Thinner lines (default was 3px) */
  background-color: #000; /* Dark color for contrast */
  transition: transform 0.3s;
}

.navbar.blurred {
  background-color: rgba(51, 51, 51, 0.8);
  backdrop-filter: blur(5px);
  WebkitBackdropFilter: blur(5px); /* For Safari */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .navbar {
    justify-content: space-between;
  }

  .logo {
    height: 30px; /* Adjust logo size for smaller screens */
    margin-right: 0; /* Remove right margin */
    margin-left: auto; /* Ensures logo stays on the right side */
  }

  .nav-links {
    display: none; /* Hide nav links by default */
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px; /* Position below navbar */
    left: 0;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .nav-links.active {
    display: flex; /* Display links when active */
  }

  .nav-links li {
    margin: 15px 0; /* Adjust spacing for vertical layout */
    text-align: center;
  }

  .hamburger {
    display: flex; /* Show hamburger on small screens */
    left: 20px; /* Ensure it stays left on mobile */
  }

  /* Rotate hamburger icon when menu is active */
  .hamburger.active div:nth-child(1) {
    transform: rotate(45deg);
    position: relative;
    top: 8px;
  }

  .hamburger.active div:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active div:nth-child(3) {
    transform: rotate(-45deg);
    position: relative;
    top: -8px;
  }
}
